import './js/header';
import './js/faq';

if (document.getElementById('reviews-swiper-wrapper')) {
  import('./js/reviews-swiper');
}

if (document.getElementById('gallery-swiper-wrapper')) {
  import('./js/gallery-swiper');
}

// if (document.getElementById('faq')) {
//   import('./js/faq.js');
// }
